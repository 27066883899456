import React from "react";
import { Route } from "react-router-dom";

import MainNavigation from "../components/Navigation/MainNavigation";
import FooterElements from "../components/Footer/FooterElements";

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => (
        <React.Fragment>
          <MainNavigation />
          <main id="main">
            <Component {...props} />
          </main>
          <FooterElements />
        </React.Fragment>
      )}
    />
  );
};
