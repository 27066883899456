import React from "react";
import { Route } from "react-router-dom";

import AdminNavigation from "../components/Navigation/AdminNavigation";

export const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => (
        <React.Fragment>
          <AdminNavigation />
          <main id="main">
            <Component {...props} />
          </main>
        </React.Fragment>
      )}
    />
  );
};
