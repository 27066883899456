import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import BackDrop from "./BackDrop";
import "./Modal.css";

const ModalOverlay = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const content = (
    <section className={`modal ${props.className}`} style={props.style}>
      {props.image && <div className="modal__image-wrapper">{props.image}</div>}
      <div className="modal__text-wrapper">
        <button className="modal__close-btn" onClick={props.onCancel}>
          <CloseRoundedIcon style={{ fontSize: 60 }} />
        </button>
        <h2 className="modal__title">{props.title}</h2>
        <p className="modal__description">{props.description}</p>
        <div className={`modal__content ${props.contentClass}`}>
          {props.content}
        </div>
        <div className="modal__form">{props.children}</div>
      </div>
    </section>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  const nodeRef = React.useRef(null);

  return (
    <React.Fragment>
      {props.show && <BackDrop onClick={props.onCancel} />}
      <CSSTransition
        nodeRef={nodeRef}
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
