import React, { useState, useContext } from "react";

import MainHeader from "./MainHeader";
import CompanyLogo from "../UIElements/CompanyLogo";
import AdminNavLinks from "./AdminNavLinks";
import SideDrawer from "./SideDrawer";
import BackDrop from "../UIElements/BackDrop";
import { AuthContext } from "../../context/auth-context";
import "./AdminNavigation.css";

const AdminNavigation = () => {
  const auth = useContext(AuthContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <BackDrop onClick={closeDrawerHandler} />}
      <SideDrawer
        show={drawerIsOpen}
        onClick={closeDrawerHandler}
        className="admin-navigation__side-drawer"
      >
        <CompanyLogo className="side-drawer__company-logo" />
        <AdminNavLinks className="side-drawer__admin-nav-links" />
      </SideDrawer>
      <MainHeader>
        <div className="main-header__admin-nav-top-section">
          <div className="container">
            {auth.isLoggedIn && (
              <div className="admin-navigation__button-wrapper">
                <button
                  className="main-navigation__menu-btn"
                  onClick={openDrawerHandler}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <button
                  className="admin-nav-links__logout-btn"
                  onClick={auth.logout}
                >
                  Logout
                </button>
              </div>
            )}
            <CompanyLogo className="admin-navigation__company-logo" />
            <nav className="admin-navigation__links">
              <AdminNavLinks />
            </nav>
          </div>
        </div>
      </MainHeader>
    </React.Fragment>
  );
};

export default AdminNavigation;
