import React from "react";

import MainFooter from "./MainFooter";
import Newsletter from "./Newsletter";
import FooterLinks from "./FooterLinks";
import "./FooterElements.css";

const FooterElements = () => {
  return (
    <MainFooter>
      <Newsletter />
      <FooterLinks />
      <div className="footer-elements__copyright">
        <div className="container">
          <p>
            COPYRIGHT &copy; 2021 VALLEY ORNAMENTS | DESIGNED BY{" "}
            <a
              href="/"
              className="footer-elements__designer-link"
              title="CoDesign"
            >
              CODESIGN
            </a>
          </p>
        </div>
      </div>
    </MainFooter>
  );
};

export default FooterElements;
