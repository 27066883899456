import React from "react";

import "./CurrentDate.css";

const CurrentDate = (props) => {
  const today = new Date();
  const options = { day: "numeric", weekday: "long", month: "long" };
  const currentDate = today.toLocaleDateString("en-US", options);

  return <span className="current-date">{currentDate}</span>;
};

export default CurrentDate;
