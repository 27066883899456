import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import MainHeader from "./MainHeader";
import PrimaryNavLinks from "./PrimaryNavLinks";
import SideDrawer from "./SideDrawer";
import BackDrop from "../UIElements/BackDrop";
import CurrentDate from "./CurrentDate";
import GoldSilverRate from "./GoldSilverRate";
import SecondaryNavLinks from "./SecondaryNavLinks";
import CompanyLogo from "../UIElements/CompanyLogo";
import SocialLinks from "../Footer/SocialLinks";
import "./MainNavigation.css";

const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
    document.body.style.overflow = "unset";
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <BackDrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <ul className="main-navigation__drawer-nav-links">
            <li>
              <NavLink to="/" exact title="Home">
                <span className="main-navigation__nav-icon">
                  <HomeIcon style={{ fontSize: 25 }} />
                </span>
                <span className="main-navigation__nav-title">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" title="About Us">
                <span className="main-navigation__nav-icon">
                  <EmojiPeopleIcon style={{ fontSize: 25 }} />
                </span>
                <span className="main-navigation__nav-title">About Us</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/contacts" title="Contacts">
                <span className="main-navigation__nav-icon">
                  <LocationOnIcon style={{ fontSize: 25 }} />
                </span>
                <span className="main-navigation__nav-title">Contacts</span>
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="side-drawer__product-categories">
          <span className="secondary-nav-links__title">Categories</span>
          <SecondaryNavLinks className="side-drawer__secondary-nav-links" />
        </div>
        <SocialLinks className="side-drawer__social-links" />
      </SideDrawer>
      <MainHeader>
        <div className="main-header__top-section">
          <div className="container">
            <button
              className="main-navigation__menu-btn"
              onClick={openDrawerHandler}
            >
              <span />
              <span />
              <span />
            </button>
            <div className="main-navigation__date-and-rate">
              <CurrentDate />
              <GoldSilverRate />
            </div>
            <CompanyLogo className="main-navigation__company-logo" />
            <nav className="main-navigation__primary-nav">
              <PrimaryNavLinks />
            </nav>
          </div>
        </div>
        <nav className="main-navigation__secondary-nav">
          <div className="container">
            <SecondaryNavLinks />
          </div>
        </nav>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
