import React from "react";

import Modal from "./Modal";
import Button from "../FormElements/Button";

const ErrorModal = (props) => {
  return (
    <Modal
      className="modal__size-small"
      onCancel={props.onClear}
      title="An Error Occurred!"
      description={props.error}
      show={!!props.error}
    >
      <Button onClick={props.onClear} inverse small>
        Okay
      </Button>
    </Modal>
  );
};

export default ErrorModal;
