import React, { useState } from "react";

import Button from "../FormElements/Button";
import Input from "../FormElements/Input";
import { VALIDATOR_EMAIL } from "../../util/validators";
import { useForm } from "../../hooks/form-hook";
import { useHttpClient } from "../../hooks/http-hook";
import ErrorModal from "../UIElements/ErrorModal";
import Modal from "../UIElements/Modal";
import "./Newsletter.css";

const Newsletter = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const cancelSubscriptionHandler = () => {
    setShowSubscriptionModal(false);
  };

  const subscriptionSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/subscription",
        "POST",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          email: formState.inputs.email.value,
        })
      );

      setShowSubscriptionModal(true);
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <Modal
        className="modal__size-small"
        show={showSubscriptionModal}
        onCancel={cancelSubscriptionHandler}
        title="Thank You."
        description="You've been added to our mailing list and will now be among the first to hear about new arrivals and special offers."
        contentClass="category-products-item__modal-actions"
        content={
          <Button small onClick={cancelSubscriptionHandler}>
            SHOP NOW
          </Button>
        }
      ></Modal>
      <div className="newsletter">
        <ErrorModal error={error} onClear={clearError} />
        <div className="container">
          <h4 className="newsletter__title">Keep in touch</h4>
          <p className="newsletter__description">
            Want to get exclusive offers and the latest news of our product
            directly in your inbox?
          </p>
          <form
            className="newsletter__form"
            onSubmit={subscriptionSubmitHandler}
          >
            <Input
              id="email"
              element="input"
              type="email"
              className="newsletter__email"
              placeholder="Subscribe to Newsletter"
              errorText="Please enter a valid email."
              validators={[VALIDATOR_EMAIL()]}
              onInput={inputHandler}
            />
            <Button type="submit" disabled={!formState.isValid}>
              Subscribe
            </Button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Newsletter;
