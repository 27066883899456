import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import "./AdminNavLinks.css";

const AdminNavLinks = (props) => {
  const auth = useContext(AuthContext);

  return (
    <ul className={`admin-nav-links ${props.className}`}>
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/products/all" exact title="My Items">
            My Items
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/products/new" title="Add Item">
            Add Item
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button className="admin-nav-links__logout-btn" onClick={auth.logout}>
            Logout
          </button>
        </li>
      )}
    </ul>
  );
};

export default AdminNavLinks;
