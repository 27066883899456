import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import "./SocialLinks.css";

const SocialLinks = (props) => {
  return (
    <ul className={`social-links ${props.className}`}>
      <li className="social-links__icon">
        <a
          href="https://www.facebook.com/Valley-ornaments-745900009545625"
          title="Facebook"
        >
          <FacebookIcon />
        </a>
      </li>
      <li className="social-links__icon" title="Instagram">
        <a href="https://www.instagram.com/valleyornaments">
          <InstagramIcon />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
