import React from "react";
import { useLocation } from "react-router-dom";

import "./MainFooter.css";

const MainFooter = (props) => {
  let location = useLocation();

  if (location.pathname === "/admin") {
    return null;
  }

  return <footer className="main-footer">{props.children}</footer>;
};

export default MainFooter;
