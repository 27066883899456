import React, { Suspense } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

import { PublicRoute } from "./shared/route/PublicRoute";
import { AdminRoute } from "./shared/route/AdminRoute";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

const Categories = React.lazy(() => import("./category/pages/Categories"));
const AboutUs = React.lazy(() => import("./aboutus/pages/AboutUs"));
const Contact = React.lazy(() => import("./contact/pages/Contact"));
const CategoryProducts = React.lazy(() =>
  import("./categoryProducts/pages/CategoryProducts")
);
const Product = React.lazy(() => import("./product/pages/Product"));
const NewProduct = React.lazy(() => import("./product/pages/NewProduct"));
const UpdateProduct = React.lazy(() => import("./product/pages/UpdateProduct"));
const AdminCategoryProducts = React.lazy(() =>
  import("./categoryProducts/pages/AdminCategoryProducts")
);
const Auth = React.lazy(() => import("./user/pages/Auth"));

const App = () => {
  const { token, login, logout } = useAuth();
  let routes;

  if (token) {
    routes = (
      <Switch>
        <AdminRoute
          path="/products/all"
          exact={true}
          component={AdminCategoryProducts}
        />
        <AdminRoute path="/products/new" exact={true} component={NewProduct} />
        <AdminRoute path="/products/:productId" component={UpdateProduct} />
        <Redirect to="/products/all" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <PublicRoute path="/" exact={true} component={Categories} />
        <PublicRoute
          path="/:categoryId/products"
          exact={true}
          component={CategoryProducts}
        />
        <PublicRoute path="/product/:id" exact={true} component={Product} />
        <PublicRoute path="/aboutus" exact={true} component={AboutUs} />
        <PublicRoute path="/contacts" exact={true} component={Contact} />
        <AdminRoute path="/authenticate" exact={true} component={Auth} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <Suspense
          fallback={
            <div className="center">
              <LoadingSpinner />
            </div>
          }
        >
          {routes}
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
