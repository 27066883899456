import React from "react";

import FooterNavLinks from "./FooterNavLinks";
import SocialLinks from "./SocialLinks";
import "./FooterLinks.css";

const FooterLinks = () => {
  return (
    <div className="footer-links">
      <div className="container">
        <a href="/" className="footer-links__logo" title="Valley Ornaments Homepage">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-footer.png`}
            width="260"
            height="26"
            alt="valley ornaments logo"
          />
        </a>
        <FooterNavLinks />
        <SocialLinks />
      </div>
    </div>
  );
};

export default FooterLinks;
