import React, { useState, useEffect } from "react";

import { useHttpClient } from "../../hooks/http-hook";

import "./GoldSilverRate.css";

const GoldSilverRate = () => {
  const { isLoading, sendRequest } = useHttpClient();
  let [goldAndSilverRates, setGoldAndSilverRates] = useState();

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem("goldAndSilverRateData")
    );
    const fetchData = async () => {
      try {
        const api = process.env.REACT_APP_BACKEND_URL + `/rates`;
        const responseRateData = await sendRequest(api);
        setGoldAndSilverRates(responseRateData);
        localStorage.setItem(
          "goldAndSilverRateData",
          JSON.stringify({ goldAndSilver: responseRateData })
        );
      } catch (err) {}
    };

    if (storedData) {
      setGoldAndSilverRates(storedData.goldAndSilver);
    } else {
      fetchData();
    }
  }, [sendRequest]);

  // Removes the localStorage data when the browser closes.
  window.onbeforeunload = () => {
    localStorage.removeItem("goldAndSilverRateData");
  };

  return (
    <React.Fragment>
      <table className="gold-silver-rate">
        <tbody>
          <tr>
            <th className="gold-silver-rate__title" scope="row">
              Gold Rate:
            </th>
            <td>{`Rs ${
              !isLoading &&
              goldAndSilverRates &&
              new Intl.NumberFormat().format(
                goldAndSilverRates.goldRate.currentRate
              )
            } / Tola`}</td>
          </tr>
          <tr>
            <th className="gold-silver-rate__title" scope="row">
              Silver Rate:
            </th>
            <td>{`Rs ${
              !isLoading &&
              goldAndSilverRates &&
              new Intl.NumberFormat().format(
                goldAndSilverRates.silverRate.currentRate
              )
            } / Tola`}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default GoldSilverRate;
