import React from "react";
import { NavLink } from "react-router-dom";

import "./SecondaryNavLinks.css";

const SecondaryNavLinks = (props) => {
  return (
    <ul className={`secondary-nav-links ${props.className}`}>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_RINGS_ID}/products`}
          exact
          title="Rings"
        >
          Rings
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_EARRINGS_ID}/products`}
          title="Earrings"
        >
          Earrings
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_BRACELETS_ID}/products`}
          title="Bracelets"
        >
          Bracelets
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_NECKLACES_ID}/products`}
          title="Necklaces"
        >
          Necklaces
        </NavLink>
      </li>
      <li>
        <NavLink to={`/${process.env.REACT_APP_TOPS_ID}/products`} title="Tops">
          Tops
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_CHAIN_ID}/products`}
          title="Chain"
        >
          Chain
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_WEDDING_ID}/products`}
          title="Wedding"
        >
          Wedding
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_BANGLES_ID}/products`}
          title="Bangles"
        >
          Bangles
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_TRADITIONAL_ID}/products`}
          title="Traditional"
        >
          Traditional
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/${process.env.REACT_APP_SILVERITEMS_ID}/products`}
          title="Silver Items"
        >
          Silver Items
        </NavLink>
      </li>
    </ul>
  );
};

export default SecondaryNavLinks;
