import React from "react";
import { Link } from "react-router-dom";

import "./CompanyLogo.css";

const CompanyLogo = (props) => {
  return (
    <div className={`company-logo ${props.className}`}>
      <Link to="/" title="Valley Ornaments Homepage">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          width="378"
          height="63"
          alt="valley ornaments logo"
        />
      </Link>
    </div>
  );
};

export default CompanyLogo;
