import React from "react";

import "./FooterNavLinks.css";

const FooterNavLinks = () => {
  return (
    <React.Fragment>
      <div className="footer-nav-links__secondary-links">
        <span className="footer-nav-links__heading">Links</span>
        <ul className="footer-nav-links__items">
          <li>
            <a
              href={`/${process.env.REACT_APP_RINGS_ID}/products`}
              title="Rings"
            >
              Rings
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_EARRINGS_ID}/products`}
              title="Earrings"
            >
              Earrings
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_BRACELETS_ID}/products`}
              title="Bracelets"
            >
              Bracelets
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_NECKLACES_ID}/products`}
              title="Necklaces"
            >
              Necklaces
            </a>
          </li>
          <li>
            <a href={`/${process.env.REACT_APP_TOPS_ID}/products`} title="Tops">
              Tops
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_CHAIN_ID}/products`}
              title="Chain"
            >
              Chain
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_WEDDING_ID}/products`}
              title="Wedding"
            >
              Wedding
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_BANGLES_ID}/products`}
              title="Bangles"
            >
              Bangles
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_TRADITIONAL_ID}/products`}
              title="Traditional"
            >
              Traditional
            </a>
          </li>
          <li>
            <a
              href={`/${process.env.REACT_APP_SILVERITEMS_ID}/products`}
              title="Silver Items"
            >
              Silver Items
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-nav-links__primary-links">
        <span className="footer-nav-links__heading">Company</span>
        <ul className="footer-nav-links__items">
          <li className="footer-nav-links__item">
            <a href="/aboutus" title="About us">
              About Us
            </a>
          </li>
          <li className="footer-nav-links__item">
            <a href="/contacts" title="Our Contacts">
              Contacts
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default FooterNavLinks;
