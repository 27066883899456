import React from "react";
import { NavLink } from "react-router-dom";

import "./PrimaryNavLinks.css";

const PrimaryNavLinks = () => {
  return (
    <ul className="primary-nav-links">
      <li>
        <NavLink to="/" exact title="Home">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/aboutus" title="About Us">
          About Us
        </NavLink>
      </li>
      <li>
        <NavLink to="/contacts" title="Contacts">
          Contacts
        </NavLink>
      </li>
    </ul>
  );
};

export default PrimaryNavLinks;
